import { isMobile } from '../utils/index'
export default {
  address: '淡水區中山北路一段215號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.7086050955895!2d121.44173547608186!3d25.179315232423484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a5559fd38325%3A0x5cf25008d13bcf69!2zMjUx5paw5YyX5biC5reh5rC05Y2A5Lit5bGx5YyX6Lev5LiA5q61MjE16Jmf!5e0!3m2!1szh-TW!2stw!4v1709890544111!5m2!1szh-TW!2stw',
  googleLink: 'https://maps.app.goo.gl/gHbFYBQsBgxoWQFDA',
  phone: '02-7728-7777',
  fbLink: 'https://www.facebook.com/ntpc.tamsui/',
  fbMessage: 'https://m.me/ntpc.tamsui/',
  caseName: '自遊城',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '溪福建設股份有限公司',],
    ['建築設計', '沈伯卿建築師',],
    ['建照號碼', '109淡建字第00539號',],
    ['行銷企劃', '澄邑廣告',],
    ['經  紀  人', '黃翠潔(99)嘉縣字第000108號(換發)',],
  ],

  gtmCode: ['TXR7L7K'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
    room_type: ['2房','3房','透天'],//房型
  },
  lixin_logo:true, //false

}