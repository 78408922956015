export default [
  /* */
  { name: '宜居生活圈', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '自由雙城心', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:-60,mobileOffset:0,  type: '' },
  { name: '便利交通', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:-60,mobileOffset:0, type: '' },
  { name: '未來淡水', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '5分鐘學區', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '自由城居', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '嚴選建材', imgSrc: '', subTitle: '', section: 'section10', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  // { name: '樣品屋預留', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '',offset:-60, mobileOffset:0 , type: '' },
  
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:-60,mobileOffset:0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
]
